
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.surveys-detail-table {
  &__head {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: $base-gutter / 1.5;
    padding: 0px 27px 8px;
  }
  &__head-item {
    color: $color-black-op-50;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: $base-gutter / 1.5;
  }
  &__actions {
    text-align: center;
  }
  &__item {
    position: relative;

    &:hover {
      box-shadow: 0px 0px 20px rgba(28, 41, 61, 0.05), 0px 12px 20px rgba(28, 41, 61, 0.06);
      transition: box-shadow $base-animation;
    }
  }
  &__item-inner {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 2;
    padding: 10px 27px;
    background-color: $color-white;
    border-radius: $base-border-radius;
    font-weight: 500;
  }
  &__hat {
    position: absolute;
    top: 0;
    left: -8px;
    height: 32px;
    width: 24px;
    border-radius: $base-border-radius;
    z-index: 1;
    opacity: .25;
  }
  &__theme {
    position: relative;
    display: grid;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  &__priority {
    position: absolute;
    top: 2px;
    left: -16px;
    .esmp-svg-icon {
      width: 12px;
      height: 12px;
    }
  }

  &__action {
    color: $color-black;
    font-weight: 500;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: $base-gutter / 3;

    .esmp-user-avatar {
      flex-shrink: 0;
    }
  }
}
